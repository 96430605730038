import {
  Container,
  EContainerBackground,
  EContainerVariant,
  EDefaultCardBackground,
} from '@front/shared/ds';
import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';

import { Scheme } from './Scheme';
import { SwiperSlider } from './SwiperSlider';
import { DefaultCard } from '../CustomDefaultCard';

export type TSpaceWithDynamicTextRow = {
  spotBroker: string;
  exchange: string;
  cost: string;
  setupTime: string;
  spotBrokerList: {
    title: string;
    description: string;
    id?: string;
  }[];
  exchangeList: {
    title: string;
    description: string;
    id?: string;
  }[];
  id?: string;
  blockName?: string;
  blockType: 'space-with-dynamic-text';
};

type TSpaceWithDynamicTextRowProps = {
  row: TSpaceWithDynamicTextRow;
};

export const SpaceWithDynamicTextRow: React.FC<
  TSpaceWithDynamicTextRowProps
> = ({ row }) => {
  const [activeSlide, setActiveSlide] = useState('');
  useEffect(() => {
    setActiveSlide(row.spotBrokerList[0].id || '');
  }, [row.spotBrokerList]);

  const renderCards = (
    list: typeof row.spotBrokerList,
    eyebrow: string,
  ): ReactNode => {
    return list.map(slide => (
      <DefaultCard
        key={slide.id}
        tags={[{ children: eyebrow }]}
        background={EDefaultCardBackground.White}
        title={slide.title}
        description={slide.description}
        className={clsx(
          'hidden',
          activeSlide === slide.id ? '2xl:flex' : '2xl:hidden',
        )}
      />
    ));
  };

  return (
    <>
      <div className='flex w-full flex-col gap-4 overflow-hidden px-4 2xl:hidden'>
        <SwiperSlider eyebrow={row.spotBroker} list={row.spotBrokerList} />
        <SwiperSlider eyebrow={row.exchange} list={row.exchangeList} />
      </div>
      <Container
        variant={EContainerVariant.Default}
        containerBackground={EContainerBackground.White}
        overflowHidden
      >
        <div className='flex justify-center gap-4 2xl:justify-end'>
          {renderCards(row.spotBrokerList, row.spotBroker)}
          {renderCards(row.exchangeList, row.exchange)}
          <Scheme
            {...row}
            onMouseEnter={(id: string) => {
              setActiveSlide(id);
            }}
          />
        </div>
      </Container>
    </>
  );
};
