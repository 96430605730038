import {
  Container,
  ImageWithPlaceholder,
  toImageProps,
} from '@front/shared/ds';
import { Media } from '@shared/master-types';
import clsx from 'clsx';
import React from 'react';

import { ContentBar, ETitleContentGap } from './ContentBar';
import { ERichTextGap, TContentBarItem } from './ContentBarItem';

export enum EHorizontalBoxBackground {
  Transparent = 'transparent',
  White = 'white',
}

export const enum EBoxBackground {
  Transparent = 'Transparent',
  White = 'White',
  Light = 'Light',
}

export type THorizontalBoxRow = {
  id?: string;
  blockType?: 'horizontal-box';
  blockName?: string;
  style?: {
    isRounded: boolean;
    background: EHorizontalBoxBackground;
    titleContentGap: ETitleContentGap;
    richTextGap: ERichTextGap;
  };
  box: {
    id?: string;
    isLeftImg: boolean;
    image: Media;
    title: string;
    content: TContentBarItem[];
    background?: EBoxBackground;
  }[];
};

type THorizontalBoxRowProps = {
  row: THorizontalBoxRow;
};

export const HorizontalBoxRow: React.FC<THorizontalBoxRowProps> = ({ row }) => {
  return (
    <Container>
      <div
        className={clsx('flex flex-col gap-8 lg:gap-16', {
          'rounded-3xl': row.style?.isRounded,
          'bg-surface-50':
            row.style?.background === EHorizontalBoxBackground.White,
        })}
      >
        {row.box.map(boxElement => {
          const boxImageProps = toImageProps(boxElement.image);
          return (
            <div
              className={clsx(
                'flex flex-col rounded-[1.5rem] lg:gap-4',
                boxElement.isLeftImg ? 'xl:flex-row-reverse' : 'xl:flex-row',
                boxElement.background === EBoxBackground.White &&
                  'bg-surface-50',
                boxElement.background === EBoxBackground.Light &&
                  'bg-surface-100',
              )}
              key={boxElement.id}
            >
              <ContentBar
                content={boxElement.content}
                title={boxElement.title}
                titleContentGap={row.style?.titleContentGap}
                richTextGap={row.style?.richTextGap}
              />

              {boxImageProps && (
                <div className='w-full'>
                  <ImageWithPlaceholder
                    className='w-full'
                    {...boxImageProps}
                    alt={boxImageProps.alt}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Container>
  );
};
