import clsx from 'clsx';
import React from 'react';

import AccountCard, { TAccountCardProps } from './AccountCard';
import { useAccountCardAnimations } from './useAccountCardsAnimation';

export type TAccountCardsProps = {
  accounts: TAccountCardProps[];
};

const AccountCards: React.FC<TAccountCardsProps> = props => {
  const { accounts = [] } = props;
  const { ref, transitionClasses } = useAccountCardAnimations();

  return (
    <div
      ref={ref}
      className='container mx-auto mb-[-20%] flex origin-top scale-[0.6] flex-row items-center justify-center gap-4 md:mb-0 md:transform-none lg:pb-6'
    >
      {accounts.map((account, index) => (
        <div
          key={account.id}
          className={clsx(
            'transition-all duration-300 ease-in',
            transitionClasses[index],
          )}
        >
          <AccountCard {...account} />
        </div>
      ))}
    </div>
  );
};

export default AccountCards;
