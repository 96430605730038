import { getGaId } from '@front/shared';
import {
  Button,
  RichText,
  TAction,
  EHeroSectionTitleTheme,
  EButtonTheme,
  EButtonSize,
} from '@front/shared/ds';
import clsx from 'clsx';
import React from 'react';

export enum EHeroSectionTitlePosition {
  Left = 'left',
  Center = 'center',
}

export type THeroSectionTitleProps = {
  title: string;
  richTitle: {
    [k: string]: unknown;
  }[];
  description?: string;
  actions?: TAction[];
  eyebrowsBelow?: string[];
  eyebrowsAbove?: string[];
  position?: EHeroSectionTitlePosition;
  theme?: EHeroSectionTitleTheme;
  className?: string;
  textBetweenActions?: string;
};

export const HeroSectionTitle: React.FC<THeroSectionTitleProps> = ({
  description,
  eyebrowsAbove,
  actions = [],
  position = EHeroSectionTitlePosition.Center,
  theme = EHeroSectionTitleTheme.Theme1,
  className,
  richTitle,
  textBetweenActions,
}) => {
  return (
    <div
      className={clsx(
        'mx-auto grid w-full gap-8 lg:mt-0 xl:max-w-[64rem] xl:auto-rows-max xl:gap-12',
        className,
      )}
    >
      <div
        className={clsx(
          'mx-auto flex flex-col gap-6 ',
          position === EHeroSectionTitlePosition.Center
            ? 'text-center'
            : 'text-left',
        )}
      >
        {!!eyebrowsAbove?.length && (
          <div>
            {eyebrowsAbove.map((eyebrow, index) => (
              <span
                className={
                  'text-xs font-medium uppercase leading-relaxed tracking-[.18rem] text-interface-500 2xl:text-base 2xl:leading-normal 2xl:tracking-[0.24em]'
                }
                key={index}
              >
                {eyebrow}
              </span>
            ))}
          </div>
        )}
        <RichText
          className={clsx(
            'whitespace-pre-line break-words prose-h1:text-7xl prose-h1:font-semibold prose-h1:leading-snug prose-strong:font-bold prose-em:font-medium xl:prose-h1:text-8xl xl:prose-h1:leading-normal 2xl:prose-h1:text-9xl',
            {
              'text-interface-1000': theme === EHeroSectionTitleTheme.Theme1,
              'text-interface-50':
                theme === EHeroSectionTitleTheme.Theme4 ||
                theme === EHeroSectionTitleTheme.Theme5,
            },
          )}
          content={richTitle}
        />

        {description && (
          <h2
            className={clsx(
              ' whitespace-pre-line text-base leading-extra-loose xl:mx-auto xl:max-w-[38.75rem]',
              {
                'text-interface-800': theme === EHeroSectionTitleTheme.Theme1,
                'text-interface-50/80':
                  theme === EHeroSectionTitleTheme.Theme4 ||
                  theme === EHeroSectionTitleTheme.Theme5,
              },
            )}
          >
            {description}
          </h2>
        )}
      </div>

      {!!actions.length && (
        <div className='mx-auto flex w-full flex-wrap items-center justify-center gap-6 xl:max-w-[35rem] xl:flex-row 2xl:max-w-[50rem] '>
          {actions.map((action, index) => (
            <React.Fragment key={index}>
              <div className='flex items-center justify-center whitespace-nowrap first:basis-full md:first:basis-auto'>
                {'props' in action && (
                  <Button
                    theme={EButtonTheme.Tertiary}
                    size={EButtonSize.Large}
                    {...action.props}
                    data-ga-id={getGaId(action.dataGaId || action.text, 'hero')}
                  />
                )}
              </div>
              {textBetweenActions && index === 0 && actions.length > 1 && (
                <span className='text-lg font-medium leading-normal text-interface-500'>
                  {textBetweenActions}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
