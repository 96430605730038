import React, { useState, ReactNode, useMemo } from 'react';

import { NavMenuContext } from './NavMenuContext';

export type TNavMenuProviderProps = { children: ReactNode };

export const NavMenuProvider: React.FC<TNavMenuProviderProps> = ({
  children,
}) => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const value = useMemo(
    () => ({ activeSection, setActiveSection }),
    [activeSection],
  );

  return (
    <NavMenuContext.Provider value={value}>{children}</NavMenuContext.Provider>
  );
};
