import { isMobile } from '@front/master';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

export type TSectionProps = {
  title: string;
  description: string;
  expanded: boolean;
  onSelect: () => void;
};

const Section: React.FC<TSectionProps> = props => {
  const { title, description, expanded, onSelect } = props;
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setIsDesktop(!isMobile());
  }, []);

  return (
    <article
      onClick={onSelect}
      className='flex flex-col items-center md:cursor-pointer'
    >
      <div className='flex justify-between gap-7 self-stretch p-6 md:px-0'>
        <div
          className={clsx(
            'hidden w-0.5 transform-gpu rounded-sm bg-gradient-to-b from-surface-100-input to-[#A0A3BA] opacity-0 md:flex',
            expanded
              ? 'animate-vertical-progress origin-top md:opacity-100'
              : 'md:h-0',
          )}
        />
        <div className='flex flex-1 flex-col'>
          <h2 className='text-3xl font-semibold leading-relaxed text-interface-1000 xl:line-clamp-3'>
            {title}
          </h2>
          <AnimateHeight
            animateOpacity
            duration={500}
            height={isDesktop && !expanded ? 0 : 'auto'}
          >
            <p
              className={clsx(
                'mt-4 text-base leading-mega-loose text-interface-800 transition-all duration-1000 [clip-path:inset(0%)] xl:line-clamp-[9]',
                expanded
                  ? 'md:mt-4'
                  : 'md:mt-0 md:[clip-path:inset(0%_0%_100%)]',
              )}
            >
              {description}
            </p>
          </AnimateHeight>
        </div>
      </div>
    </article>
  );
};

export default Section;
