import { MutableRefObject, useEffect, useRef, useState } from 'react';

const animation = [
  [
    'translate-x-32 opacity-0 z-10',
    'translate-x-20 opacity-0 z-20',
    'translate-x-0 opacity-100 z-30',
    '-translate-x-20 opacity-0 z-20',
    '-translate-x-32 opacity-0 z-10',
  ],
  [
    'translate-x-32 opacity-0 z-10',
    'opacity-100 z-20',
    'opacity-100 z-30',
    'opacity-100 z-20',
    '-translate-x-32 opacity-0 z-10',
  ],
  [
    'opacity-100 z-10',
    'opacity-100 z-20',
    'opacity-100 z-30',
    'opacity-100 z-20',
    'opacity-100 z-10',
  ],
];

const delay = 500; // 0,5 seconds

export type TUseAccountCardAnimations = () => {
  ref: MutableRefObject<HTMLDivElement | null>;
  transitionClasses: string[];
};

type TTimer = ReturnType<typeof setTimeout>;

export const useAccountCardAnimations: TUseAccountCardAnimations = () => {
  const [transitionClasses, setTransitionClasses] = useState(animation[0]);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let timer1: TTimer;
    let timer2: TTimer;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            timer1 = setTimeout(setTransitionClasses, delay, animation[1]);
            timer2 = setTimeout(setTransitionClasses, delay * 2, animation[2]);
          } else {
            setTransitionClasses(animation[0]);
          }
        });
      },
      { threshold: 0.8 },
    );

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return { ref, transitionClasses };
};
