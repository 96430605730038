import { useRef, useEffect, MutableRefObject } from 'react';

export const useSectionObserver = (
  id?: string,
  onVisibilityChange?: (isVisible: boolean) => void,
): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          onVisibilityChange?.(entry.isIntersecting);
        });
      },
      { threshold: 0.2 },
    );

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [id, onVisibilityChange]);

  return ref;
};
