import { useContext } from 'react';

import { TNavMenuContext, NavMenuContext } from './NavMenuContext';

export const useNavMenu = (): TNavMenuContext => {
  const context = useContext(NavMenuContext);

  if (!context) {
    throw new Error('useNavMenu must be used within a NavMenuProvider');
  }

  return context;
};
