import {
  Container,
  isRichTextContentEmpty,
  RichText,
  toImageProps,
  toLinkProps,
  TRichTextContent,
} from '@front/shared/ds';
import { Document, Media, Page } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import { SpaceXlDouble } from './SpaceXlDouble';

export type TSpaceXlDoubleRow = {
  id?: string;
  blockType?: 'space-xl-double';
  blockName?: string;
  box: {
    logo?: Media;
    icon?: Media;
    title: string;
    description: TRichTextContent;
    links: {
      linkType: 'internal' | 'custom';
      url: string;
      doc:
        | {
            value: string | Page;
            relationTo: 'pages';
          }
        | {
            value: string | Document;
            relationTo: 'documents';
          };
      rel: ('nofollow' | 'noopener' | 'noreferrer' | 'external')[];
    }[];
  }[];
};

type TDoubleSpaceRowProps = {
  row: TSpaceXlDoubleRow;
};

export const SpaceXlDoubleRow: React.FC<TDoubleSpaceRowProps> = props => {
  const box = props.row?.box;
  const router = useRouter();
  const currentLocale = String(router.query.locale);
  return (
    <Container>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
        {box?.map((element, key) => {
          const link = element.links?.[0];
          return (
            <SpaceXlDouble
              description={
                element.description &&
                !isRichTextContentEmpty(element.description) ? (
                  <RichText content={element.description} />
                ) : null
              }
              title={element.title}
              link={toLinkProps(currentLocale, link) || null}
              icon={toImageProps(element?.icon) || null}
              logo={toImageProps(element?.logo)}
              key={key}
            />
          );
        })}
      </div>
    </Container>
  );
};
