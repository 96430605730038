import { EDefaultCardBackground } from '@front/shared/ds';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DefaultCard } from '../CustomDefaultCard';

export type TSwiperSliderProps = {
  list: {
    title: string;
    description: string;
    id?: string;
  }[];
  eyebrow: string;
};

export const SwiperSlider: React.FC<TSwiperSliderProps> = props => {
  const { list, eyebrow } = props;

  return (
    <Swiper
      modules={[Mousewheel]}
      spaceBetween={8}
      slidesPerView={'auto'}
      className='flex overflow-visible'
      wrapperClass={'flex'}
      mousewheel={{ releaseOnEdges: true, forceToAxis: true }}
    >
      {!!list?.length &&
        list.map(slide => (
          <SwiperSlide
            className='h-[unset] shrink-0 grow-0 basis-80'
            key={slide.id}
          >
            <DefaultCard
              tags={[{ children: eyebrow }]}
              background={EDefaultCardBackground.White}
              title={slide.title}
              description={slide.description}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
