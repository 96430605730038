import {
  Container,
  ESchemeSwipeVariant,
  ImageWithPlaceholder,
  RichText,
  SchemeSwipe,
  toImageProps,
  TRichTextContent,
} from '@front/shared/ds';
import { Media } from '@shared/master-types';
import React from 'react';

export type THedgingRow = {
  id?: string;
  blockType?: 'hedging';
  blockName?: string;
  swipeText: string;
  swipeTheme?: ESchemeSwipeVariant;
  descriptionTitle?: string;
  scheme: Media;
  description?: TRichTextContent;
};

type THedgingRowProps = {
  row: THedgingRow;
};

export const HedgingRow: React.FC<THedgingRowProps> = ({ row }) => {
  const schemeImageProps = toImageProps(row.scheme);
  return (
    <Container overflowHidden>
      <div className='flex flex-col gap-8 2xl:gap-16 2xl:px-0'>
        {schemeImageProps && (
          <div>
            <div>
              <div className='scrollbar-hidden mx:px-0 -mx-4 overflow-scroll scroll-smooth md:mx-0 md:rounded-t-2xl md:px-0'>
                <div className='mx-0 min-w-[46.25rem] px-4 md:relative md:min-w-0 md:px-0 lg:w-full lg:min-w-0'>
                  <ImageWithPlaceholder
                    className='block w-full rounded-t-2xl border-2 border-surface-100 md:border-none'
                    {...schemeImageProps}
                    alt={schemeImageProps.alt}
                  />
                </div>
              </div>
            </div>
            {row.swipeText && (
              <SchemeSwipe
                swipeTheme={row.swipeTheme}
                swipeText={row.swipeText}
              />
            )}
          </div>
        )}
        {row.descriptionTitle || row.description ? (
          <div className='flex w-full flex-col gap-4 xl:mx-auto xl:max-w-[51rem]'>
            {row.descriptionTitle && (
              <h3 className='text-3xl font-semibold leading-relaxed text-interface-1000 2xl:text-5xl 2xl:leading-extra-loose'>
                {row.descriptionTitle}
              </h3>
            )}
            {row.description && (
              <RichText
                className='flex flex-col gap-3 font-normal leading-[1.65rem] text-interface-800 prose-strong:font-normal prose-strong:text-interface-1000'
                content={row.description}
              />
            )}
          </div>
        ) : null}
      </div>
    </Container>
  );
};
