import { ImageWithPlaceholder } from '@front/shared/ds';
import { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import React from 'react';

export type TCardWithFooterProps = {
  title?: string;
  description?: React.JSX.Element | string;
  image?: ImageProps | null;
  link?: LinkProps | null;
};

export const CardWithFooter: React.FC<TCardWithFooterProps> = ({
  title,
  description,
  image,
  link,
}) => {
  const cardClassname =
    'flex min-h-[20.5rem] flex-col gap-8 rounded-3xl border-2 border-control-200 p-6 h-full';
  const cardContent = (
    <>
      {title || description ? (
        <div className='flex flex-col gap-4'>
          {title ? (
            <span className='text-2xl font-semibold leading-extra-loose text-interface-1000'>
              {title}
            </span>
          ) : null}
          {description ? (
            <span className='text-base font-normal leading-mega-loose text-interface-800'>
              {description}
            </span>
          ) : null}
        </div>
      ) : null}
      {image && (
        <ImageWithPlaceholder
          className='mt-auto block h-auto max-h-8 w-full max-w-[14.5rem] object-contain object-left rtl:object-right'
          {...image}
        />
      )}
    </>
  );

  if (link) {
    return (
      <Link {...link} className={cardClassname}>
        {cardContent}
      </Link>
    );
  }

  return <div className={cardClassname}>{cardContent}</div>;
};
