import {
  EBorderStyle,
  EDefaultCardBackground,
  EIconPosition,
  EIconSize,
  ICardProps,
  ImageWithPlaceholder,
} from '@front/shared/ds';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

const dashedBorder =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23C8CAD9FF' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e";

export type TDefaultCardProps = ICardProps & {
  image?: ImageProps | null;
  background?: EDefaultCardBackground;
};

export const DefaultCard: React.FC<TDefaultCardProps> = props => {
  const {
    image = null,
    background = EDefaultCardBackground.Light,
    title,
    tags,
    icon,
    iconSize,
    iconPosition,
    description,
    borderStyle,
    className,
  } = props;

  let cardBackground = 'bg-surface-100';
  if (background === EDefaultCardBackground.White) {
    cardBackground = 'bg-surface-50';
  }

  return (
    <div
      style={
        {
          '--custom-dashed-border': `url("${dashedBorder}")`,
        } as React.CSSProperties
      }
      className={clsx(
        'relative flex h-full flex-1 flex-col justify-between overflow-hidden rounded-3xl border-surface-100',
        borderStyle === EBorderStyle.Solid && 'border-2 border-solid',
        cardBackground,
        className,
      )}
    >
      {borderStyle === EBorderStyle.Dashed && (
        <div className='absolute block h-full w-full bg-[image:var(--custom-dashed-border)]' />
      )}
      {(!!tags?.length || title || icon || description) && (
        <div
          className={clsx(
            'relative flex flex-col p-6 md:p-5 lg:p-6 xl:p-8',
            icon &&
              iconPosition === EIconPosition.Top &&
              !tags?.length &&
              !title
              ? 'gap-3'
              : 'gap-4',
          )}
        >
          <div
            className={clsx(
              {
                'flex gap-4': iconPosition !== EIconPosition.Right,
              },
              icon && iconPosition === EIconPosition.RightCenter
                ? 'flex-row justify-center'
                : 'flex-col',
            )}
          >
            {icon && (
              <ImageWithPlaceholder
                {...icon}
                className={clsx(
                  'object-contain',
                  iconSize === EIconSize.XxxL && 'h-[4.5rem]',
                  iconSize === EIconSize.XxL && 'h-[3.5rem]',
                  iconSize === EIconSize.Xl && 'h-[2.25rem]',
                  iconSize === EIconSize.L && 'h-[1.5rem]',
                  {
                    'float-right ms-2 mt-1 rtl:float-left':
                      iconPosition === EIconPosition.Right,
                    'order-2': iconPosition === EIconPosition.RightCenter,
                  },
                )}
                alt={icon.alt || ''}
              />
            )}

            {(!!tags?.length || title) && (
              <div className='flex flex-1 flex-col gap-4'>
                {!!tags?.length && (
                  <div className='flex flex-wrap gap-3 text-base font-medium uppercase leading-relaxed tracking-[.2rem] text-interface-500'>
                    {tags.map(tag => (
                      <span key={String(tag.children)}>{tag.children}</span>
                    ))}
                  </div>
                )}

                {title && (
                  <h3
                    className={clsx(
                      'text-3xl font-semibold leading-extra-loose text-interface-1000 lg:text-4xl xl:text-5xl',
                    )}
                  >
                    {title}
                  </h3>
                )}
              </div>
            )}
          </div>

          {description && (
            <div
              className={clsx(
                'text-base leading-mega-loose transition empty:hidden prose-a:text-brand-500 prose-a:hover:text-brand-550',
                'prose-p:pb-3 last:prose-p:pb-0',
                'text-interface-800 prose-ul:flex prose-ul:flex-col prose-ul:gap-1',
              )}
            >
              {description}
            </div>
          )}
        </div>
      )}

      {image && (
        <ImageWithPlaceholder
          {...image}
          alt={image.alt}
          className='h-auto w-full object-cover'
        />
      )}
    </div>
  );
};
