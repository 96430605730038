import {
  Container,
  ESchemeSwipeVariant,
  ImageWithPlaceholder,
  SchemeSwipe,
  toImageProps,
} from '@front/shared/ds';
import { Media } from '@shared/master-types';
import React from 'react';

export type TSchemeBreakDownRow = {
  id?: string;
  blockType?: 'scheme-breakdown';
  blockName?: string;
  swipeText: string;
  swipeTheme?: ESchemeSwipeVariant;
  providersTitle: string;
  logoList: {
    logo: Media;
  }[];
  scheme: Media;
};

type TSchemeBreakDownRowProps = {
  row: TSchemeBreakDownRow;
};

export const SchemeBreakDownRow: React.FC<TSchemeBreakDownRowProps> = ({
  row,
}) => {
  const schemeImageProps = toImageProps(row.scheme);
  return (
    <Container overflowHidden>
      <div className='flex flex-col gap-4 md:gap-0 xl:px-4 2xl:px-0'>
        {schemeImageProps && (
          <div>
            <div>
              <div className='scrollbar-hidden mx:px-0 -mx-4  overflow-scroll scroll-smooth md:mx-0 md:rounded-t-2xl md:px-0 xl:mx-0'>
                <div className='mx-0 min-w-[46.25rem] px-4 md:relative md:min-w-0 md:px-0 '>
                  <ImageWithPlaceholder
                    className='block w-full'
                    {...schemeImageProps}
                    alt={schemeImageProps.alt}
                  />
                </div>
              </div>
            </div>
            {row.swipeText && (
              <SchemeSwipe
                swipeTheme={row.swipeTheme}
                swipeText={row.swipeText}
              />
            )}
          </div>
        )}
        <div className='rounded-2xl bg-interface-50 p-6 md:-mt-6 md:rounded-b-2xl md:rounded-t-none md:pt-12 xl:mx-0'>
          {row.providersTitle && (
            <h3 className='mb-6 text-center text-lg font-medium text-interface-1000'>
              {row.providersTitle}
            </h3>
          )}
          {row.logoList && (
            <div className='flex w-full flex-wrap justify-center gap-4  md:mx-auto md:flex md:max-w-[39.688rem] md:grid-cols-8 lg:max-w-[42rem] xl:max-w-[58rem] 2xl:max-w-[73rem]'>
              {row.logoList.map((image, imgIndex) => {
                const imageProps = toImageProps(image.logo);
                if (!imageProps) return null;

                return (
                  <div
                    className='h-[3rem] w-[8.125rem]'
                    key={imageProps.alt + imgIndex}
                  >
                    <ImageWithPlaceholder
                      className='h-full w-full object-cover'
                      {...imageProps}
                      alt={imageProps.alt}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
