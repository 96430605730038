import { RichText as DefaultRichText, TRichTextProps } from '@front/shared/ds';
import clsx from 'clsx';
import { FC } from 'react';

export const RichText: FC<TRichTextProps> = ({ className, ...rest }) => {
  return (
    <DefaultRichText
      {...rest}
      className={clsx(
        // List item styles specific for B2Broker
        '[&>ul]:leading-[1.65rem]',
        className,
      )}
    />
  );
};
