import { isMobile } from '@front/master';
import {
  Container,
  ImageWithPlaceholder,
  toImageProps,
} from '@front/shared/ds';
import React, { Fragment, useEffect, useState } from 'react';

import Section, { TSectionProps } from './Section';
import SectionMedia, { TSectionMediaProps } from './SectionMedia';
import { useSections } from './useSections';

export type TAccordionWithVideoProps = {
  id: string;
  blockType?: 'accordion-with-video';
  blockName?: string;
  sections: (TSectionProps & TSectionMediaProps)[];
  isLight?: boolean;
};

const AccordionWithVideo: React.FC<TAccordionWithVideoProps> = ({
  sections,
  isLight,
}) => {
  const { ref, activeSectionIndex, setActiveSectionIndex } = useSections({
    sectionsCount: sections.length,
  });
  const activeSection =
    sections[activeSectionIndex === -1 ? 0 : activeSectionIndex];
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setIsDesktop(!isMobile());
  }, []);

  return (
    <Container>
      <main
        ref={ref}
        className='self-stretch rounded-[32px] 2xl:min-h-[41.5rem]'
      >
        <section className='flex gap-5 max-md:flex-col'>
          <aside className='flex w-[60%] flex-col max-md:ml-0 max-md:w-full xl:w-[30%]'>
            {sections.map(({ title, description }, index) => {
              const fallbackImage = toImageProps(sections[index].mediaFallback);
              return (
                <Fragment key={title}>
                  <Section
                    title={title}
                    description={description}
                    expanded={activeSectionIndex === index}
                    onSelect={() => setActiveSectionIndex(index)}
                  />

                  {/* For Mobile only */}
                  {!isDesktop && (
                    <div className='flex pb-8'>
                      {fallbackImage ? (
                        <ImageWithPlaceholder
                          {...fallbackImage}
                          className='w-full rounded-[0.625rem]'
                          alt={fallbackImage.alt}
                        />
                      ) : (
                        <SectionMedia
                          isLight={isLight}
                          autoplay={activeSectionIndex !== -1} // Do not load video until user does not see the section
                          media={sections[index].media}
                          hasGradient={sections[index].hasGradient}
                          hasBorders={sections[index].hasBorders}
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </aside>

          {/* For Desktop only */}
          {isDesktop && (
            <SectionMedia
              isLight={isLight}
              autoplay={activeSectionIndex !== -1} // Do not load video until user does not see the section
              media={activeSection.media}
              hasGradient={activeSection.hasGradient}
              hasBorders={activeSection.hasBorders}
            />
          )}
        </section>
      </main>
    </Container>
  );
};

export default AccordionWithVideo;
