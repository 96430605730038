import {
  useGetPageQuery,
  useGetLocalesQuery,
  isPopulatedReference,
  PageLayout,
} from '@front/master';
import { resolveObservedGetStaticProps } from '@front/master/components/pages/SectionsPage';
import { NavMenuProvider } from '@front/master/context';
import { Page, Section } from '@shared/master-types';
import React from 'react';

import { SectionsList } from './components';
import { HeroConnected } from '../../blocks';

export type TSectionsPageProps = {
  id: string;
  locale: string;
  draft: boolean;
};

const SectionsPage: React.FC<TSectionsPageProps> = props => {
  const { draft, id, locale } = props;

  const { data: localesObject } = useGetLocalesQuery({ locale, draft });
  const { data } = useGetPageQuery({ id, draft, locale, depth: 3 });

  const locales = localesObject?.docs;

  const page = data as Page;

  if (!page) {
    return null;
  }

  const sections = (page?.parts?.sections?.defaultSections || []) as Section[];
  const hero = isPopulatedReference(page?.parts?.hero)
    ? page?.parts?.hero
    : undefined;
  const isShowBreadcrumbs = !page?.isBreadcrumbsHidden && !hero;

  return (
    <NavMenuProvider>
      <PageLayout
        locale={locale}
        draft={draft}
        meta={{
          ...page?.meta,
          url: page?.url,
        }}
        headerDepth={2}
        pageQueryDepth={3}
        breadcrumbs={isShowBreadcrumbs ? page?.breadcrumbs : []}
        pageId={id}
      >
        {!!hero && <HeroConnected hero={hero} locales={locales} />}
        <SectionsList sections={sections} draft={draft} locale={locale} />
      </PageLayout>
    </NavMenuProvider>
  );
};

export { getStaticPaths } from '@front/master/components/pages/SectionsPage';

export const getStaticProps = resolveObservedGetStaticProps({
  headerDepth: 2,
  depth: 3,
});

export default SectionsPage;
