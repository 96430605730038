import { ReactNode, useCallback } from 'react';

import { useNavMenu } from './useNavMenu';
import { useSectionObserver } from '../../hooks/useSectionObserver';

export type TNavMenuSectionObserverProps = {
  id?: string;
  children: ReactNode;
};

export const NavMenuSectionObserver: React.FC<TNavMenuSectionObserverProps> = ({
  id,
  children,
}) => {
  const { activeSection, setActiveSection } = useNavMenu();

  const onVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible && id) {
        setActiveSection(id);
        return;
      }
      if (id === activeSection) {
        setActiveSection(null);
      }
    },
    [activeSection, setActiveSection, id],
  );

  const ref = useSectionObserver(id, onVisibilityChange);

  if (!id) {
    return <>{children}</>;
  }

  return <div ref={ref}>{children}</div>;
};
