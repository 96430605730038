import {
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

export type TUseSections = ({ sectionsCount }: { sectionsCount: number }) => {
  activeSectionIndex: number;
  setActiveSectionIndex: (value: SetStateAction<number>) => void;
  ref: MutableRefObject<HTMLDivElement | null>;
};

const INTERVAL_MS = 8000; // 8 seconds

export const useSections: TUseSections = ({ sectionsCount }) => {
  const [activeSectionIndex, setActiveSectionIndex] = useState(-1);
  const ref = useRef<HTMLDivElement | null>(null);
  const refInterval = useRef<ReturnType<typeof setTimeout>>();

  // Start from the first section
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (entry.intersectionRatio >= 0.8) {
              setActiveSectionIndex(val => (val === -1 ? 0 : val));
            }
          } else {
            clearInterval(refInterval.current);
            setActiveSectionIndex(-1);
          }
        });
      },
      { threshold: [0.1, 0.8] },
    );

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  useEffect(() => {
    // Set up an interval to update the active section index
    if (activeSectionIndex !== -1) {
      if (refInterval.current) {
        clearInterval(refInterval.current);
      }
      refInterval.current = setInterval(() => {
        setActiveSectionIndex(prevIndex => (prevIndex + 1) % sectionsCount);
      }, INTERVAL_MS);
    }

    return () => {
      clearInterval(refInterval.current);
    };
  }, [sectionsCount, activeSectionIndex]);

  return { activeSectionIndex, setActiveSectionIndex, ref };
};
