import { VideoResponsive } from '@front/master/components/common/VideoResponsive';
import { ImageWithPlaceholder } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import clsx from 'clsx';
import { toImageProps } from 'front/shared/ds/src/utils';
import React, { useEffect, useRef, useState } from 'react';

export type TSectionMediaProps = {
  media: Media;
  mediaFallback?: Media;
  autoplay: boolean;
  className?: string;
  isLight?: boolean;
  hasGradient?: boolean;
  hasBorders?: boolean;
};

const SectionMedia: React.FC<TSectionMediaProps> = ({
  media,
  autoplay,
  className,
  isLight,
  hasGradient = true,
  hasBorders = true,
}) => {
  const [src, setSrc] = useState(media.url);
  const ref = useRef<HTMLVideoElement>(null);
  const [invisible, setInvisible] = useState(false);

  const isVideo = media.mimeType?.includes('video/');
  const image = !isVideo && toImageProps(media);

  useEffect(() => {
    const handleLoadedMetadata = (): void => {
      if (ref.current?.parentElement) {
        // Used to prevent blinking of the video section
        ref.current.parentElement.style.height = `${ref.current.clientHeight}px`;
      }
    };

    handleLoadedMetadata();

    if (ref.current) {
      ref.current.addEventListener('loadedmetadata', handleLoadedMetadata, {
        once: true,
      });
    }

    setInvisible(true);

    setTimeout(() => {
      setSrc(media.url);
      setInvisible(false);
    }, 500);
  }, [media.url]);

  useEffect(() => {
    if (!isVideo) {
      return;
    }
    if (autoplay) {
      ref.current?.play().catch(err => {
        // eslint-disable-next-line no-console
        console.log('Failed to play video', err);
      });
    } else {
      ref.current?.pause();
    }
  }, [autoplay, isVideo]);

  return (
    <div
      className={clsx(
        'relative ml-5 flex w-[67%] flex-col rounded-md border-b-0 border-r-0 max-md:ml-0 max-md:w-full md:rounded-3xl',
        className,
        {
          'border-surface-50-input': isLight,
          'border-surface-100-input': !isLight,
          'border-2': hasBorders,
        },
      )}
    >
      {isVideo ? (
        <VideoResponsive
          ref={ref}
          className={clsx(
            'rounded-md border-b-0 border-r-0 object-top opacity-0 transition-opacity duration-500 md:rounded-3xl',
            {
              'opacity-100': !invisible,
            },
          )}
          loop
          muted
          autoPlay={autoplay}
          playsInline={autoplay}
          preload='metadata'
          video={src}
        />
      ) : (
        image &&
        src && (
          <ImageWithPlaceholder
            {...image}
            src={src}
            className={clsx(
              'w-full rounded-[0.625rem] opacity-0 transition-opacity duration-500',
              {
                'opacity-100': !invisible,
              },
            )}
            key={src}
            alt={image.alt}
          />
        )
      )}

      {hasGradient && (
        <>
          <div
            className={clsx(
              'absolute bottom-[-5px] left-[-2px] flex h-[15%] w-full bg-gradient-to-b to-80%',
              isLight ? 'to-surface-100-input' : 'to-surface-50-input',
              isLight ? 'from-surface-100-input/0' : 'from-surface-50-input/0',
            )}
          ></div>
          <div
            className={clsx(
              'absolute right-[-5px] top-[-2px] flex h-full w-[8%] bg-gradient-to-r to-80%',
              isLight ? 'to-surface-100-input' : 'to-surface-50-input',
              isLight ? 'from-surface-100-input/0' : 'from-surface-50-input/0',
            )}
          ></div>
        </>
      )}

      {/* Preload next video while animating */}
      {isVideo && invisible && (
        <div className='invisible absolute'>
          <VideoResponsive video={media} autoPlay muted playsInline />
        </div>
      )}

      {/* Preload next image while animating */}
      {image && invisible && (
        <div className='invisible absolute'>
          <ImageWithPlaceholder {...image} alt={image.alt} loading='eager' />
        </div>
      )}
    </div>
  );
};

export default SectionMedia;
