import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CardWithFooter, TCardWithFooterProps } from './CardWithFooter';

export type TContent = {
  cards: TCardWithFooterProps[];
};

export const Content: React.FC<TContent> = ({ cards }) => {
  return cards?.length ? (
    <div className='mx-auto w-full overflow-hidden'>
      <Swiper
        className='flex max-w-[79rem] overflow-visible px-4'
        wrapperClass={'flex max-w-fit mx-auto'}
        spaceBetween={8}
        slidesPerView='auto'
        breakpoints={{
          1024: {
            slidesPerView: 4,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          687: {
            slidesPerView: 'auto',
            spaceBetween: 16,
          },
        }}
      >
        {cards.map((card, index) => (
          <SwiperSlide
            className='h-auto w-[18.875rem] tablet:w-[14.75rem] desktop:w-auto'
            key={index}
          >
            <CardWithFooter {...card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};
