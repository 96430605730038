export const enum ESolidTabSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Outline = 'outline',
}

export const enum ESolidTabTheme {
  Ordinary = 'ordinary',
  Sticked = 'sticked',
}
