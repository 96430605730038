import { prepareDefaultCardFromRawData } from '@front/master/components/blocks/DefaultCardsConnected';
import { useAppDispatch } from '@front/master/store/store.hooks';
import {
  Container,
  EDefaultCardsHorizontalScroll,
  EDefaultCardsAlign,
  EDefaultCardsColumns,
  useDragScrolling,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { RichText } from '../../blocks/RichText';
import { DefaultCard } from '../CustomDefaultCard';

export type TDefaultCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'default-cards' }
> & { customType: 'b2broker-main-default-cards' };

type TCustomDefaultCardsRowProps = {
  row: TDefaultCardsConnectedProps;
  section?: Section;
};

export const CustomDefaultCardsRow: React.FC<TCustomDefaultCardsRowProps> = ({
  row,
}) => {
  const { cards = [], horizontalScroll, align, columns = [] } = row;
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const { scrollableRef, bind } = useDragScrolling({
    enabled: horizontalScroll !== EDefaultCardsHorizontalScroll.Disabled,
  });

  const banners: React.ComponentProps<typeof DefaultCard>[] = useMemo(() => {
    return cards.map(card => {
      const defaultCard = prepareDefaultCardFromRawData(
        card,
        currentLocale,
        appDispatch,
      );

      defaultCard.description = <RichText content={card.description} />;
      return defaultCard;
    });
  }, [cards, currentLocale, appDispatch]);

  return (
    <section
      ref={scrollableRef}
      className={clsx(
        'scrollbar-hidden flex',
        horizontalScroll === EDefaultCardsHorizontalScroll.Enabled &&
          'overflow-auto',
        horizontalScroll === EDefaultCardsHorizontalScroll.Mobile &&
          'overflow-auto xl:overflow-hidden',
        horizontalScroll === EDefaultCardsHorizontalScroll.Desktop &&
          'xl:overflow-auto',
      )}
      {...bind()}
    >
      <Container>
        <div
          className={clsx(
            '-mx-2 flex items-stretch gap-y-4',
            align === EDefaultCardsAlign.Start && 'justify-start',
            align === EDefaultCardsAlign.End && 'justify-end',

            align === EDefaultCardsAlign.Center &&
              horizontalScroll === EDefaultCardsHorizontalScroll.Disabled &&
              'justify-center',
            align === EDefaultCardsAlign.Center &&
              horizontalScroll === EDefaultCardsHorizontalScroll.Mobile &&
              'xl:justify-center',
            align === EDefaultCardsAlign.Center &&
              horizontalScroll === EDefaultCardsHorizontalScroll.Desktop &&
              'justify-center xl:justify-start',

            horizontalScroll === EDefaultCardsHorizontalScroll.Disabled &&
              'flex-wrap',
            horizontalScroll === EDefaultCardsHorizontalScroll.Enabled &&
              'flex-nowrap',
            horizontalScroll === EDefaultCardsHorizontalScroll.Mobile &&
              'flex-nowrap xl:flex-wrap',
            horizontalScroll === EDefaultCardsHorizontalScroll.Desktop &&
              'flex-wrap xl:flex-nowrap',
          )}
        >
          {banners.map((banner, i) => (
            <div
              key={i}
              className={clsx(
                'min-w-[95%] basis-full px-2',
                banners.length > 2 ? 'md:min-w-[49%]' : 'md:min-w-[50%]',
                'md:basis-1/2',
                columns === EDefaultCardsColumns.Three &&
                  'xl:min-w-[33.33%] xl:basis-1/3',
                columns === EDefaultCardsColumns.Four &&
                  '2xl:min-w-[25%] 2xl:basis-1/4',
              )}
            >
              <DefaultCard {...banner} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
