import {
  VideoResponsive,
  isMobile,
  useActions,
  useHeroImages,
  HeroAnimatedSliderConnected,
} from '@front/master';
import { default as DefaultCardsConnected } from '@front/master/components/blocks/DefaultCardsConnected';
import { getGaId } from '@front/shared';
import {
  Container,
  EContainerVariant,
  FixedElement,
  EHeroSectionTitleTheme,
  toImageProps,
  getMediaSrc,
  ImageWithPlaceholder,
} from '@front/shared/ds';
import { Hero, Locale } from '@shared/master-types';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { ReactNode, useEffect, useState } from 'react';

import { HeroSectionTitle } from '../../rows/CustomHeroSectionTitle';

export enum ESectionBackground {
  Transparent = 'transparent',
  Light = 'light',
}

export enum EHeroBackground {
  Primary = 'primary',
  Light = 'light',
  Dark = 'dark',
  Transparent = 'transparent',
}

export type THeroConnectedProps = {
  hero: Hero;
  locales?: Locale[];
  customImages?: ReactNode[];
  background?: EHeroBackground;
  sectionBackground?: ESectionBackground;
};

const HeroConnected: React.FC<THeroConnectedProps> = ({
  hero,
  customImages,
}) => {
  const {
    title,
    subtitle,
    background = EHeroBackground.Light,
    sectionBackground = ESectionBackground.Transparent,
    sectionTitleTheme = EHeroSectionTitleTheme.Theme1,
    eyebrowsBottom,
    eyebrowsTop,
    richTitle,
    textBetweenActions,
  } = hero;

  const heroActions = useActions(hero?.actions);

  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setIsDesktop(!isMobile());
  }, []);

  const poster =
    typeof hero?.videoPoster !== 'string' ? hero?.videoPoster?.url : '';
  const isVideoRounded = !!hero?.isVideoRounded;
  const preparedPoster = getMediaSrc(poster);

  const fixedAction = useActions(hero?.fixedElement?.action);

  const { images } = useHeroImages({ hero, customImages });

  const eyebrowsAbove = (eyebrowsTop || []).map(eyebrow => eyebrow.text);
  const eyebrowsBelow = (eyebrowsBottom || []).map(eyebrow => eyebrow.text);
  const fallbackImage = toImageProps(hero.videoPoster);

  if (hero?.variant === 'AnimatedHeroSlider') {
    return <HeroAnimatedSliderConnected hero={hero} />;
  }

  return (
    <div
      className={clsx('overflow-hidden', {
        'bg-transparent': sectionBackground === ESectionBackground.Transparent,
        'bg-surface-100': sectionBackground === ESectionBackground.Light,
      })}
    >
      <Container
        variant={EContainerVariant.Main}
        dataGaId={getGaId(hero.name || hero.title, 'hero')}
        isNoPaddingOnMobile
      >
        <div className='flex flex-col gap-12 px-4 py-12 xl:gap-18 xl:px-0 xl:py-18'>
          <section
            className={clsx('relative ', {
              'flex min-h-[15.5rem] items-center justify-center py-[4.875rem] md:min-h-[30rem] md:py-0':
                hero && hero?.variant === 'CoverImage',
            })}
          >
            {!!images?.length && (
              <div className='pointer-events-none absolute inset-0 overflow-hidden rounded-3xl'>
                {images}
              </div>
            )}

            <div>
              {fixedAction?.map((action, index) => (
                <FixedElement key={index} action={action} />
              ))}
              <div
                className={clsx(
                  'grid items-center rounded-3xl bg-brand-100',
                  {
                    'bg-brand-500': background === EHeroBackground.Primary,
                    'bg-surface-1000': background === EHeroBackground.Dark,
                    'bg-surface-100': background === EHeroBackground.Light,
                    'bg-transparent':
                      background === EHeroBackground.Transparent,
                  },
                  hero && hero?.variant === 'ThreeImages' && 'pt-[10.938rem]',
                )}
              >
                <HeroSectionTitle
                  title={title}
                  richTitle={richTitle || []}
                  description={subtitle}
                  eyebrowsAbove={eyebrowsAbove}
                  eyebrowsBelow={eyebrowsBelow}
                  actions={heroActions}
                  theme={sectionTitleTheme as EHeroSectionTitleTheme}
                  className='z-10'
                  textBetweenActions={textBetweenActions}
                />
              </div>
            </div>
          </section>
          {hero.cardBlocks?.map(row => (
            <DefaultCardsConnected key={row.id} {...row} />
          ))}
          {hero &&
            hero?.variant === 'Video' &&
            typeof hero?.video !== 'string' && (
              <motion.div
                className={clsx(
                  isVideoRounded ? 'overflow-hidden rounded-3xl' : '',
                )}
                initial={{ opacity: 0, y: 60 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, ease: 'easeIn' }}
              >
                {!isDesktop && fallbackImage ? (
                  <ImageWithPlaceholder
                    {...fallbackImage}
                    className='w-full'
                    alt={fallbackImage.alt}
                  />
                ) : (
                  <VideoResponsive
                    loop
                    playsInline
                    muted
                    autoPlay
                    poster={preparedPoster}
                    video={hero.video}
                  />
                )}
              </motion.div>
            )}
        </div>
      </Container>
    </div>
  );
};
export default HeroConnected;
