import { Container, EContainerVariant } from '@front/shared/ds';
import React from 'react';

import AccountCards, { TAccountCardsProps } from './AccountCards';
import Description from './Description';

export type TMulticurrencyAccountsProps = {
  id: string;
  blockType?: 'multicurrency-accounts';
  blockName?: string;
  accounts: TAccountCardsProps['accounts'];
  description?: string;
  subDescription?: string;
};

const MulticurrencyAccounts: React.FC<TMulticurrencyAccountsProps> = props => {
  const { description, subDescription, accounts = [] } = props;

  return (
    <Container variant={EContainerVariant.Full} overflowHidden>
      <AccountCards accounts={accounts} />
      {description && subDescription && (
        <Description
          description={description}
          subDescription={subDescription}
        />
      )}
    </Container>
  );
};

export default MulticurrencyAccounts;
