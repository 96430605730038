import {
  ImageWithPlaceholder,
  toImageProps,
  TRichTextContent,
} from '@front/shared/ds';
import { Media } from '@shared/master-types';
import clsx from 'clsx';
import React from 'react';

import { RichText } from '../../blocks/RichText';

export enum ERichTextGap {
  Sm = 'sm',
  Base = 'base',
}

export type TContentBarItem = {
  icon?: Media;
  text: TRichTextContent;
};

export const ContentBarItem: React.FC<{
  item: TContentBarItem;
  richTextGap?: ERichTextGap;
}> = ({ item, richTextGap }) => {
  const iconImageProps = toImageProps(item.icon);
  return (
    <div>
      {iconImageProps ? (
        <ImageWithPlaceholder
          className='mb-4 mt-2 h-[2rem] w-full max-w-[2rem] object-contain object-left 2xl:mt-4'
          width={32}
          height={32}
          {...iconImageProps}
          alt={iconImageProps.alt}
        />
      ) : null}
      <RichText
        className={clsx(
          'flex flex-col font-normal leading-mega-loose text-interface-700 prose-strong:font-normal prose-strong:text-light-interface-1000 prose-li:mt-[0.2rem] prose-li:leading-mega-loose first:prose-li:mt-0',
          {
            'gap-4 ': richTextGap === ERichTextGap.Base,
            'gap-2 ': richTextGap === ERichTextGap.Sm,
          },
        )}
        content={item.text}
      />
    </div>
  );
};
